import { getPlayerInfo } from '@/api/player'
import tool from '@/utils/tool'

const state = {
  info: tool.data.get('player') || {},
  extend: tool.data.get('playerExtend') || {},
}

const mutations = {
  SET_PLAYER: (state, player) => {
    tool.data.set('player', player)
    state.info = player
  },
  SET_PLAYER_EXTEND: (state, playerExtend) => {
    tool.data.set('playerExtend', playerExtend)
    state.extend = playerExtend
  },
}

const actions = {
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getPlayerInfo()
        .then(res => {
          commit('SET_PLAYER', res.data.player)
          commit('SET_PLAYER_EXTEND', res.data.playerExtend)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
