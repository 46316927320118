<template>
  <van-dialog class="subscribe-dialog" v-model:show="showSubscribeDialog" confirm-button-text="关闭" @confirm="checkSubscribe" title="长按二维码关注公众号">
    <div class="subscribe-qrcode"><img src="/images/common/subscribe-qrcode.jpg" /></div>
  </van-dialog>
</template>
<script>
export default {
  name: 'Subscribe',
}
</script>
<script setup>
import { Dialog, Toast } from 'vant'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'

const store = useStore()
const VanDialog = Dialog.Component

const showSubscribeDialog = computed({
  get() {
    return Object.keys(store.state.user.info).length > 0 && !store.state.user.info.subscribe
  },
  set(val) {
    return val
  },
})

const checkSubscribe = () => {
  let res = store.dispatch('user/getInfo').then(res => {
    if (!res.data.user.subscribe) {
      Toast('未关注月之底公众号无法关闭，请长按二维码关注公众号')
    }
  })
}
</script>

<style lang="scss" scoped>
.subscribe-qrcode {
  width: 100%;
  text-align: center;
}
</style>
