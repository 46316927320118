import { Dialog, Toast } from 'vant'
import axios from 'axios'
import sysConfig from '@/config'
import tool from '@/utils/tool'
import wechat from '@/utils/wechat'
import store from '@/store'
import { bindSteamConfirm } from '@/utils/user'
import router from '@/router'

axios.defaults.baseURL = sysConfig.API_URL
axios.defaults.timeout = sysConfig.TIMEOUT

let acitveAxios = 0
let loadingToast
const showLoading = () => {
  acitveAxios++
  if (acitveAxios > 0) {
    loadingToast = Toast.loading({
      message: '',
      forbidClick: true,
      duration: 0,
    })
  }
}

const closeLoading = () => {
  acitveAxios--
  if (acitveAxios <= 0) {
    loadingToast && loadingToast.clear()
  }
}

// HTTP request 拦截器
axios.interceptors.request.use(
  config => {
    if (!config.hideLoading) {
      showLoading()
    }

    let token = tool.data.get('token')
    if (token) {
      config.headers[sysConfig.HEADER_TOKEN_NAME] = sysConfig.HEADER_TOKEN_PREFIX + token
    }
    if (!sysConfig.REQUEST_CACHE && config.method == 'get') {
      config.params = config.params || {}
      config.params['_'] = new Date().getTime()
    }
    Object.assign(config.headers, sysConfig.HEADERS)
    return config
  },
  error => {
    if (!error.config.hideLoading) {
      closeLoading()
    }
    return Promise.reject(error)
  }
)

// HTTP response 拦截器
axios.interceptors.response.use(
  response => {
    if (!response.config.hideLoading) {
      closeLoading()
    }
    if (response.data.code && !response.config.hideFailToast) {
      if (response.data.code == 1001) {
        bindSteamConfirm('', response.data.message)
      } else {
        Toast({
          message: response.data.message || '服务器出小差了，请稍后再试[1]！',
          forbidClick: true,
          onClose: () => {
            if (response.data.code == 1002) {
              store.dispatch('user/getInfo')
            }
          },
        })
      }

      return Promise.reject(response.data)
    }
    return response
  },
  error => {
    if (!error.config.hideLoading) {
      closeLoading()
    }
    if (error.response) {
      if (error.response.status == 401) {
        tool.data.remove('token')
        Dialog.alert({
          title: '登录失效',
          message: '登录已失效，请重新登录！',
          confirmButtonText: '重新登录',
          forbidClick: true,
          theme: 'round-button',
        }).then(() => {
          wechat.authorize()
        })
      } else {
        Toast({
          message: error.response.data.message || '服务器出小差了，请稍后再试[2]！',
          forbidClick: true,
        })
      }
    } else {
      Toast('网络异常，请稍后再试！')
    }
    return Promise.reject(error.response.data || error)
  }
)

export default {
  /** get 请求
   * @param  {接口地址} url
   * @param  {请求参数} params
   * @param  {参数} config
   */
  get: function (url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: url,
        params: params,
        ...config,
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /** post 请求
   * @param  {接口地址} url
   * @param  {请求参数} data
   * @param  {参数} config
   */
  post: function (url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: url,
        data: data,
        ...config,
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /** put 请求
   * @param  {接口地址} url
   * @param  {请求参数} data
   * @param  {参数} config
   */
  put: function (url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'put',
        url: url,
        data: data,
        ...config,
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /** patch 请求
   * @param  {接口地址} url
   * @param  {请求参数} data
   * @param  {参数} config
   */
  patch: function (url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'patch',
        url: url,
        data: data,
        ...config,
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /** delete 请求
   * @param  {接口地址} url
   * @param  {请求参数} data
   * @param  {参数} config
   */
  delete: function (url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'delete',
        url: url,
        data: data,
        ...config,
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /** jsonp 请求
   * @param  {接口地址} url
   * @param  {JSONP回调函数名称} name
   */
  jsonp: function (url, name = 'jsonp') {
    return new Promise(resolve => {
      var script = document.createElement('script')
      var _id = `jsonp${Math.ceil(Math.random() * 1000000)}`
      script.id = _id
      script.type = 'text/javascript'
      script.src = url
      window[name] = response => {
        resolve(response)
        document.getElementsByTagName('head')[0].removeChild(script)
        try {
          delete window[name]
        } catch (e) {
          window[name] = undefined
        }
      }
      document.getElementsByTagName('head')[0].appendChild(script)
    })
  },
}
