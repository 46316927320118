/**
 * 工具模块
 */

import CryptoJS from 'crypto-js'
const tool = {}

/* 操作localStorage */
tool.data = {
  set(table, settings) {
    var _set = JSON.stringify(settings)
    return localStorage.setItem(table, _set)
  },
  get(table) {
    var data = localStorage.getItem(table)
    try {
      data = JSON.parse(data)
    } catch (err) {
      return null
    }
    return data
  },
  remove(table) {
    return localStorage.removeItem(table)
  },
  clear() {
    return localStorage.clear()
  },
}

/* 操作sessionStorage */
tool.session = {
  set(table, settings) {
    var _set = JSON.stringify(settings)
    return sessionStorage.setItem(table, _set)
  },
  get(table) {
    var data = sessionStorage.getItem(table)
    try {
      data = JSON.parse(data)
    } catch (err) {
      return null
    }
    return data
  },
  remove(table) {
    return sessionStorage.removeItem(table)
  },
  clear() {
    return sessionStorage.clear()
  },
}

/* 复制对象 */
tool.objCopy = function (obj) {
  return JSON.parse(JSON.stringify(obj))
}

/* 日期格式化 */
tool.dateFormat = function (date, fmt = 'yyyy-MM-dd hh:mm:ss') {
  date = new Date(date)
  var o = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'h+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return fmt
}

/* 当前时间戳 */
tool.now = function () {
  return parseInt(new Date().getTime() / 1000)
}

/* 千分符 */
tool.groupSeparator = function (num) {
  num = num + ''
  if (!num.includes('.')) {
    num += '.'
  }
  return num
    .replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
      return $1 + ','
    })
    .replace(/\.$/, '')
}

/* 常用加解密 */
tool.crypto = {
  //MD5加密
  MD5(data) {
    return CryptoJS.MD5(data).toString()
  },
  //BASE64加解密
  BASE64: {
    encrypt(data) {
      return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data))
    },
    decrypt(cipher) {
      return CryptoJS.enc.Base64.parse(cipher).toString(CryptoJS.enc.Utf8)
    },
  },
  //AES加解密
  AES: {
    encrypt(data, secretKey) {
      const result = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      })
      return result.toString()
    },
    decrypt(cipher, secretKey) {
      const result = CryptoJS.AES.decrypt(cipher, CryptoJS.enc.Utf8.parse(secretKey), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      })
      return CryptoJS.enc.Utf8.stringify(result)
    },
  },
}

tool.getUrlParam = function (name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substring(1).match(reg) //匹配目标参数
  if (r != null) return encodeURIComponent(r[2])
  return null //返回参数值
}
export default tool
