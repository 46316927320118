import { Dialog } from 'vant'
import tool from './tool'
import router from '@/router'

export const contactService = () => {
  window.location.href = 'https://work.weixin.qq.com/kfid/kfc1640633efc55e2e7'
}

export const openOnlineService = () => {
  Dialog.confirm({
    title: '',
    message: '需要立即联系在线客服?',
    confirmButtonText: '立即联系',
    cancelButtonText: '稍后再说',
    theme: 'round-button',
  }).then(() => {
    window.location.href = 'https://work.weixin.qq.com/kfid/kfc1640633efc55e2e7'
  })
}

export const redirectPage = () => {
  let fromRoute = tool.data.get('fromRoute')
  let authorize = tool.data.get('authorize')
  tool.data.remove('authorize')
  tool.data.remove('fromRoute')

  if (authorize && fromRoute) {
    router.replace(fromRoute)
  } else {
    window.history.length <= 1 || authorize ? router.replace({ name: 'UserIndex' }) : router.back()
  }
}
