import { login } from '@/api/login'
import { getUserInfo } from '@/api/user'
import tool from '@/utils/tool'

const state = {
  info: tool.data.get('user') || {},
  token: tool.data.get('token') || '',
}

const mutations = {
  SET_TOKEN: (state, token) => {
    tool.data.set('token', token)
    state.token = token
  },
  SET_USER_INFO: (state, user) => {
    tool.data.set('user', user)
    state.info = user
  },
  SET_SUBSCRIBE: (state, subscribe) => {
    let user = tool.data.get('user')
    user.subscribe = subscribe
    tool.data.set('user', user)
    state.info.subscribe = subscribe
  },
}

const actions = {
  login({ commit }, code) {
    return new Promise((resolve, reject) => {
      login({ code })
        .then(res => {
          commit('SET_TOKEN', res.data.token)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getUserInfo()
        .then(res => {
          commit('SET_USER_INFO', res.data.user)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
