import http from '@/utils/request'

/**
 * 微信授权登录获取TOKEN
 * @param {code:""} data
 * @returns
 */
export const login = (data, config = { hideFailToast: true }) => {
  return http.post('/wechat-user/login', data, config)
}

/**
 * 获取微信用户信息
 * @returns
 */
export const getUserInfo = () => {
  return http.get('/wechat-user/info')
}

/**
 * 绑定SteamId
 * @param { steamAccount:'xxx', project: 'tui6' } data
 * @returns
 */
export const bindSteam = data => {
  return http.post('/wechat-user/bind-steam-account', data)
}

/**
 * 解绑SteamId
 * @returns
 */
export const unBindSteam = () => {
  return http.post('/wechat-user/un-bind-steam-account')
}
