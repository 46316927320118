<template>
  <div class="footer-tabbar">
    <van-tabbar active-color="#4895f7" v-model="active">
      <van-tabbar-item
        name="WelfareCenter"
        :class="{ 'animate__animated animate__bounceIn': active == 'WelfareCenter' }"
        icon="gift"
        :to="{ name: 'WelfareCenter' }"
        >礼包</van-tabbar-item
      >
      <van-tabbar-item
        name="GameRanking"
        :class="{ 'animate__animated animate__bounceIn': active == 'GameRanking' }"
        icon="bar-chart-o"
        :to="{ name: 'GameRanking' }"
      >数据</van-tabbar-item
      >
<!--      <van-tabbar-item-->
<!--        name="OnlineService"-->
<!--        :class="{ 'animate__animated animate__bounceIn': active == 'OnlineService' }"-->
<!--        @click="openOnlineService"-->
<!--        icon="service-o"-->
<!--        >客服</van-tabbar-item-->
<!--      >-->
      <van-tabbar-item icon="user-o" name="UserIndex" :class="{ 'animate__animated animate__bounceIn': active == 'UserIndex' }" :to="{ name: 'UserIndex' }"
        >我的</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<script>
export default {
  name: 'FooterTabbar',
}
</script>
<script setup>
import { Icon, Tabbar, TabbarItem } from 'vant'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { openOnlineService } from '@/utils/common'

const store = useStore()
const VanIcon = Icon
const VanTabbar = Tabbar
const VanTabbarItem = TabbarItem

const active = computed({
  get() {
    return store.state.settings.footTabbarActive
  },
  set(val) {
    return val
  },
})
</script>
<style lang="scss" scoped></style>
