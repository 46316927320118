import { Dialog, Toast } from 'vant'
import router from '@/router'
import tool from './tool'

export const bindSteamConfirm = (title, message = '') => {
  Dialog.confirm({
    title: '提示',
    message: message ? message : title + '之前需要先绑定SteamID，立即去绑定SteamID?',
    confirmButtonText: '立即绑定',
    cancelButtonText: '稍后再说',
    theme: 'round-button',
  }).then(() => {
    tool.data.set('setFromRoute', true)
    router.push({ name: 'BindSteam' })
  })
}

export const bindMobileConfirm = (title, message = '') => {
  Dialog.confirm({
    title: '提示',
    message: message ? message : title + '之前需要先绑定手机号，立即去绑定手机号?',
    confirmButtonText: '立即绑定',
    cancelButtonText: '稍后再说',
    theme: 'round-button',
  }).then(() => {
    tool.data.set('setFromRoute', true)
    router.push({ name: 'BindMobile' })
  })
}
