import http from '@/utils/request'

/**
 * 微信授权登录获取TOKEN
 * @param {code:""} data
 * @returns
 */

export function login(data, config = { hideFailToast: true }) {
  return http.post('/wechat-user/login', data, config)
}
