import config from '@/config'
import tool from '@/utils/tool'

const wechat = {}

wechat.authorize = function () {
  let redirectUri = encodeURIComponent(window.location.href)
  tool.data.set('authorize', true)
  window.location.replace(
    `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.WECHAT_APP_ID}&redirect_uri=${redirectUri}&response_type=code&scope=${config.WECHAT_OAUTH_SCOPE}&state=isWechatAuth#wechat_redirect`
  )
}

export default wechat
