import http from '@/utils/request'

/**
 * 获取玩家信息
 * @returns
 */
export const getPlayerInfo = () => {
  return http.get('/player/info')
}

/**
 * 绑定邮箱
 * @returns
 */
export const bindEmail = data => {
  return http.post('/player/bind-email', data)
}

/**
 * 绑定手机号
 * @returns
 */
export const bindMobile = data => {
  return http.post('/player/bind-mobile', data)
}
