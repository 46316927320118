import './assets/font/iconfont.css'
import router from './router'
import store from './store'
import { createApp } from 'vue'
import App from './App.vue'

store.dispatch('router/getKeepAliveRouters')
const app = createApp(App)

app.use(store)
app.use(router)

//挂载app
app.mount('#app')
