<template>
  <router-view v-slot="{ Component }" :class="{ 'footer-padding': showFooterTabbar }">
    <transition mode="out-in" enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">
      <keep-alive :include="store.state.router.keepAliveRouters"> <component :is="Component" /></keep-alive></transition
  ></router-view>
  <FooterTabbar v-if="showFooterTabbar" />
  <Subscribe />
</template>

<script setup>
import FooterTabbar from '@/components/FooterTabbar/Index'
import Subscribe from '@/components/Subscribe/Index'
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import 'animate.css'

const store = useStore()
const showFooterTabbar = computed(() => store.state.settings.showFooterTabbar)

onMounted(() => {})
</script>
<style lang="scss">
@import '@/assets/css/main.scss';
</style>
<style lang="scss" scoped>
.animate__animated.animate__zoomIn {
  --animate-duration: 300ms;
  --animate-delay: 0;
}
.animate__animated.animate__zoomOut {
  --animate-duration: 300ms;
  --animate-delay: 0;
}
</style>
