const DEFAULT_CONFIG = {
  //标题
  APP_NAME: '月之底',

  //版本号
  APP_VER: '1.0.0',

  //API接口地址
  API_URL: process.env.VUE_APP_API_URL,

  //请求超时
  TIMEOUT: 60000,

  //请求头token参数名称
  HEADER_TOKEN_NAME: 'Authorization',

  //请求头token前缀，注意最后有个空格不要删除，如不需要需设置空字符串
  HEADER_TOKEN_PREFIX: 'Bearer ',

  //追加其他头
  HEADERS: {},

  //请求是否开启缓存
  REQUEST_CACHE: false,

  //微信appid
  WECHAT_APP_ID: 'wx618b62fbd228f85d',

  //微信网页授权作用域，snsapi_base（不弹出授权页面，直接跳转，只能获取用户openid），snsapi_userinfo（弹出授权页面，可通过openid拿到昵称、性别、所在地。并且， 即使在未关注的情况下，只要用户授权，也能获取其信息 ）
  WECHAT_OAUTH_SCOPE: 'snsapi_userinfo',

  OSS_DOMAIN: '',
}

export default DEFAULT_CONFIG
