import router from '@/router'

const state = {
  keepAliveRouters: [],
}

const keepAliveRoutersArr = []
const KeepAliveFilter = routes => {
  routes &&
    routes.forEach(item => {
      // 子菜单中有 keep-alive 的，父菜单也必须 keep-alive，否则无效。这里将子菜单中有 keep-alive 的父菜单也加入。
      if ((item.children && item.children.some(ch => ch.meta.keepAlive)) || item.meta.keepAlive) {
        keepAliveRoutersArr.push(item.name)
      }
      if (item.children && item.children.length > 0) {
        KeepAliveFilter(item.children)
      }
    })
}

const mutations = {
  SET_KEEP_ALIVE_ROUTERS: (state, keepAliveRouters) => {
    state.keepAliveRouters = keepAliveRouters
  },
}

const actions = {
  getKeepAliveRouters({ commit }) {
    return new Promise(resolve => {
      KeepAliveFilter(router.getRoutes())
      commit('SET_KEEP_ALIVE_ROUTERS', keepAliveRoutersArr)
      resolve(keepAliveRoutersArr)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
