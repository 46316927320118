import { createRouter, createWebHistory } from 'vue-router'
import { Dialog } from 'vant'
import config from '@/config'
import wechat from '@/utils/wechat'
import store from '@/store'
import tool from '@/utils/tool'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/user/index',
  },
  {
    path: '/user/index',
    name: 'UserIndex',
    component: () => import('@/views/user/Index'),
    meta: {
      title: '我的',
      auth: true,
      showFooter: true,
    },
  },
  {
    path: '/user/setting',
    name: 'UserSetting',
    component: () => import('@/views/user/Setting'),
    meta: {
      title: '设置',
      auth: true,
    },
  },
  {
    path: '/steam-account/bind',
    name: 'BindSteam',
    component: () => import('@/views/user/BindSteam'),
    meta: {
      title: '绑定SteamID',
      auth: true,
    },
  },
  {
    path: '/steam-account/un-bind',
    name: 'UnBindSteam',
    component: () => import('@/views/user/UnBindSteam'),
    meta: {
      title: '解绑SteamID',
      auth: true,
    },
  },
  {
    path: '/user/bind-email',
    name: 'BindEmail',
    component: () => import('@/views/user/BindEmail'),
    meta: {
      title: '绑定邮箱',
      auth: true,
    },
  },
  {
    path: '/user/bind-mobile',
    name: 'BindMobile',
    component: () => import('@/views/user/BindMobile'),
    meta: {
      title: '绑定手机号',
      auth: true,
    },
  },
  {
    path: '/game-data/p2',
    name: 'P2',
    component: () => import('@/views/game-data/P2.vue'),
    meta: {
      title: '选技生存',
      auth: true,
      showFooter: true,
    },
  },
  {
    path: '/game-data/c5',
    name: 'C5',
    component: () => import('@/views/game-data/C5.vue'),
    meta: {
      title: '天命竞技场',
      auth: true,
      showFooter: true,
    },
  },
  {
    path: '/game-ranking/index',
    name: 'GameRanking',
    component: () => import('@/views/game-ranking/Index'),
    meta: {
      title: '数据中心',
      auth: true,
      showFooter: true,
    },
  },
  {
    path: '/game-ranking/tui6',
    name: 'Tui6Ranking',
    component: () => import('@/views/game-ranking/Tui6'),
    meta: {
      title: '秘境奇兵深渊排行榜',
      auth: true,
    },
  },
  {
    path: '/feedback/index',
    name: 'Feedback',
    component: () => import('@/views/feedback/Index'),
    meta: {
      title: '问题反馈',
      auth: true,
      keepAlive: true,
    },
  },
  {
    path: '/feedback/list',
    name: 'FeedbackList',
    component: () => import('@/views/feedback/List'),
    meta: {
      title: '问题反馈列表',
      auth: true,
    },
  },
  {
    path: '/feedback/detail',
    name: 'FeedbackDetail',
    component: () => import('@/views/feedback/Detail'),
    meta: {
      title: '问题反馈详情',
      auth: true,
    },
  },
  {
    path: '/contributor/apply',
    name: 'ContributorApply',
    component: () => import('@/views/contributor/Apply'),
    meta: {
      title: '贡献者申请',
      auth: true,
      keepAlive: true,
    },
  },
  {
    path: '/game-reservation/index',
    name: 'GameReservation',
    component: () => import('@/views/game-reservation/Index'),
    meta: {
      title: '游戏预约',
      auth: true,
    },
  },
  {
    path: '/game-reservation/detail',
    name: 'GameReservationDetail',
    component: () => import('@/views/game-reservation/Detail'),
    meta: {
      title: '游戏预约',
      auth: true,
    },
  },
  {
    path: '/welfare-center/index',
    name: 'WelfareCenter',
    component: () => import('@/views/welfare-center/Index'),
    meta: {
      title: '礼包中心',
      auth: true,
      showFooter: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/other/404'),
    meta: {
      title: '404',
      auth: false,
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title}` : `${config.APP_NAME}`

  store.dispatch('settings/changeSetting', { key: 'showFooterTabbar', value: to.meta.showFooter || false })
  store.dispatch('settings/changeSetting', { key: 'footTabbarActive', value: to.name })

  const state = to.query.state
  const token = store.state.user.token

  //如果需要登录访问并且当前token为空，则跳转到后台地址授权
  if (to.meta.auth && !token) {
    if (state && state.includes('isWechatAuth')) {
      try {
        const code = Array.isArray(to.query.code) ? to.query.code.pop() : to.query.code
        await store.dispatch('user/login', code)
      } catch (err) {
        Dialog({ title: '错误', message: err.message, theme: 'round-button', confirmButtonText: '重新登录' }).then(() => {
          wechat.authorize()
        })
        return false
      }
    } else {
      wechat.authorize()
      return false
    }
  }

  if (tool.data.get('setFromRoute')) {
    tool.data.remove('setFromRoute')
    tool.data.set('fromRoute', { name: from.name, query: from.query, params: from.params })
  }

  next()
})

export default router
